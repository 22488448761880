export const menuRoles = {
    itensMenu: {
        home: {
            roles: [
                'CADASTRO_CRACHA_SALVAR',
                'CADASTRO_CRACHA_DELETAR',
                'CADASTRO_CRACHA_VISUALIZAR'
            ],
            home: {
                visualizar: ['CADASTRO_CRACHA_VISUALIZAR'],
                salvar: ['CADASTRO_CRACHA_SALVAR'],
                deletar: ['CADASTRO_CRACHA_DELETAR']
            }
        },
        cracha: {
            roles: [
                'CADASTRO_CRACHA_SALVAR',
                'CADASTRO_CRACHA_DELETAR',
                'CADASTRO_CRACHA_VISUALIZAR'
            ],
            cracha: {
                visualizar: ['CADASTRO_CRACHA_VISUALIZAR'],
                salvar: ['CADASTRO_CRACHA_SALVAR'],
                deletar: ['CADASTRO_CRACHA_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETRIZACAO_TIPO-CRACHA_DELETAR',
                'PARAMETRIZACAO_TIPO-CRACHA_VISUALIZAR',
                'PARAMETRIZACAO_TIPO-CRACHA_SALVAR',
                'PARAMETRIZACAO_TIPO-CONTRATACAO_DELETAR',
                'PARAMETRIZACAO_TIPO-CONTRATACAO_VISUALIZAR',
                'PARAMETRIZACAO_TIPO-CONTRATACAO_SALVAR',
                'PARAMETRIZACAO_MULTA-CRACHA_DELETAR',
                'PARAMETRIZACAO_MULTA-CRACHA_VISUALIZAR',
                'PARAMETRIZACAO_MULTA-CRACHA_SALVAR'
            ],
            tipoCracha: [
                'PARAMETRIZACAO_TIPO-CRACHA_DELETAR',
                'PARAMETRIZACAO_TIPO-CRACHA_VISUALIZAR',
                'PARAMETRIZACAO_TIPO-CRACHA_SALVAR'
            ],
            tipoContratacao: [
                'PARAMETRIZACAO_TIPO-CONTRATACAO_DELETAR',
                'PARAMETRIZACAO_TIPO-CONTRATACAO_VISUALIZAR',
                'PARAMETRIZACAO_TIPO-CONTRATACAO_SALVAR'
            ],
            multaCracha: [
                'PARAMETRIZACAO_MULTA-CRACHA_DELETAR',
                'PARAMETRIZACAO_MULTA-CRACHA_VISUALIZAR',
                'PARAMETRIZACAO_MULTA-CRACHA_SALVAR'
            ]
        }
    }
};

export const rolesPersonalizadas = {
    segurancaCdt: {
        visualizar: ['PERSONALIZADAS_SEGURANCA-CDT_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_SEGURANCA-CDT_SALVAR'],
        deletar: ['PERSONALIZADAS_SEGURANCA-CDT_DELETAR']
    }
};
