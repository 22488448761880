import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/'
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    },
    {
        path: 'tipocracha',
        loadChildren: () =>
            import('./pages/cadastro-tipo-cracha/cadastro-tipo-cracha.module').then(
                (m) => m.CadastroTipoCrachaModule
            )
    },
    {
        path: 'tipocontratacao',
        loadChildren: () =>
            import('./pages/cadastro-tipo-contratacao/cadastro-tipo-contratacao.module').then(
                (m) => m.CadastroTipoContratacaoModule
            )
    },
    {
        path: 'cracha',
        loadChildren: () =>
            import('./pages/cadastro-cracha/cadastro-cracha.module').then(
                (m) => m.CadastroCrachaModule
            )
    },
    {
        path: 'multacracha',
        loadChildren: () =>
            import('./pages/cadastro-multa-cracha/cadastro-multa-cracha.module').then(
                (m) => m.CadastroMultaCrachaModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
